//import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, {useState} from "react"
import { navigate } from 'gatsby-link'

const scrollTo = (selector) => (
  typeof window !== `undefined` ?
      document.location.href === 'https://nathaliebruman-sophrologie.fr/' ?
        document.querySelector(selector).scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
    :
    navigate(`/${selector}`)
  :
    null
);

const Header = ({ siteTitle }) => {
  const [openMenu, setOpenMenu] = useState(false); 
  return(
    <nav className="navbar" style={{backgroundColor: "transparent"}}>
      <div className="container">
        <div className="navbar-brand">
          <a className="navbar-item has-text-black" style={{display: 'block'}} href="/">
            <p className="is-size-4">{siteTitle}</p>
            <p className="is-size-6"><i>Sophrologue</i></p>
          </a>
          <span className={openMenu ? "navbar-burger burger is-active has-text-white" : "navbar-burger burger has-text-white"} onClick={e => setOpenMenu(!openMenu)} style={{zIndex:"1", width:"70px"}}>
            <span></span>
            <span></span>
            <span></span>
          </span>
        </div>
        <div className={openMenu ? "navbar-menu is-active" : "navbar-menu"}>
          <div className="navbar-end">
            <a className="navbar-item has-text-black" href="/">
              <p className="is-size-5 has-text-weight-bold">Accueil</p>
            </a>
            <a className="navbar-item has-text-black" onClick={() => (setOpenMenu(!openMenu), scrollTo('#mon-approche')) }>
              <p className="is-size-5 has-text-weight-bold">Mon Approche</p>
            </a>
            <a className="navbar-item has-text-black"  onClick={() => (setOpenMenu(!openMenu), scrollTo('#a-propos'))}>
              <p className="is-size-5 has-text-weight-bold">Qui suis-je ?</p>
            </a>
            <a className="navbar-item has-text-black" href="/contact">
              <p className="is-size-5 has-text-weight-bold">Contact</p>
            </a>
          </div>
        </div>
      </div>
    </nav>
  )
}


Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
