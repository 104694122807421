import React from "react"
import PropTypes from "prop-types"
import IMGDiplome from '../images/logo_chambre_sophrologie.png'

const Footer = ({textDescriptionSite, adresse, complementAdresse, ville, codepostal}) => (
    <footer className="footer is-primary">
        <div className="container has-text-centered">
            <div className="columns is-centered">
                <div className="column is-10 has-text-white">
                    <div className="columns is-centered">
                        <div className="column is-3 is-4-tablet barre-separation">
                            <figure class="image is-128x128" style={{margin:"auto"}}>
                                <img src={IMGDiplome} alt="logo diplôme chambre soprologie" />
                            </figure>
                        </div>
                        <div className="column is-3 is-4-tablet barre-separation">
                            <p className="is-size-4 has-text-weight-semibold has-text-white">Sophrologie</p>
                            <p className="has-text-centered is-size-5 mt-2 has-text-white">{textDescriptionSite}</p>
                        </div>
                        <div className="column is-3 has-text-centered is-4-tablet">
                            <p className="is-size-4 has-text-weight-semibold has-text-white">Informations</p>
                            <div className="is-size-5 mt-2 has-text-white">
                                <p style={{ margin: "0" }}>Nathalie BRUMAN</p>
                                <p style={{ margin: "0" }}>{adresse}</p>
                                <p style={{ margin: "0" }}>{complementAdresse}</p>
                                <p style={{ margin: "0" }}>{codepostal} {ville}</p>
                            </div>
                            <p className="is-size-5 has-text-weight-semibold"><a className="has-text-white" href="/contact">Contact</a> - <a className="has-text-light" href="/mentions">Mentions légales</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content is-size-5 has-text-white" style={{ paddingTop: "40px" }}>
                <p>© 2020 Tous droits reservés © | By <a className="has-text-white" href="https://alexislepresle.com" target="_blank" rel="noopener noreferrer">alexislepresle</a></p>
            </div>
        </div>
    </footer>
)


export default Footer


Footer.propTypes = {
    textDescriptionSite: PropTypes.string,
    adresse: PropTypes.string,
    ville: PropTypes.string,
    codepostal: PropTypes.string,
}
  
Footer.defaultProps = {
    textDescriptionSite: ``,
    adresse: ``,
    ville: ``,
    codepostal: ``,
}
