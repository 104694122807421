import React from "react"
import PropTypes from "prop-types"
import "./layout.sass"
import { useStaticQuery, graphql } from "gatsby"
import Footer from "../components/footer"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      markdownRemark(frontmatter: {templateKey: {eq: "info"}}) {
        frontmatter {
          adresse
          complementAdresse
          ville
          codepostal
          tel
          textDescriptionSite
        }
      }
    }
  `)
  const { frontmatter } = data.markdownRemark 

  return (
    <>
      {children}
      <Footer 
        textDescriptionSite={frontmatter.textDescriptionSite} 
        adresse={frontmatter.adresse} 
        ville={frontmatter.ville} 
        complementAdresse={frontmatter.complementAdresse}
        codepostal={frontmatter.codepostal} 
      />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

Layout.defaultProps = {
  adresse: ``,
}

export default Layout
